$dark-blue: #0C324D;
$grey: #C6C6C6;
$light-blue: #DBEFFD;
$light-blue-hover: #d1ebff;
$orange: #E55D4D;

.Home {
  img {
    max-width: 100%;
  }
  .Home-left {
    & {
      background: url("img/bg.png");
      background-size: cover;
      padding: 0;
      height: 100%;
    }

    .Home-left-wrapper {
      & {
        background: rgba(12, 50, 77, 0.7);
        padding: 40px 40px;
        height: 100%;
      }

      li {
        & {
          font-family: Inter;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          color: #fff;
          margin-bottom: 20px;
          list-style-image: url("img/list.png");
          list-style-position: outside;
        }
        span {
          position: relative;
          top: -15px;
        }
      }
    }
  }

  .Home-right {
    .MuiTypography-body1{
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color:$dark-blue;
      margin-bottom:15px;
    }

    .MuiTypography-body1.last {
      margin-bottom: 40px;
    }

    .MuiTypography-body2{
      font-family: Inter;
      font-size: 15px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color:$dark-blue;
      margin-bottom:15px;
    }

    li {
      font-family: Inter;
      font-size: 15px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color:$dark-blue;
      margin-bottom:15px;
      list-style-image: url("img/list-r.png");
    }

    button.MuiButton-root {
      background: #FE442F;
      box-shadow: 0px 8px 10px 0px #FE442F24;
      font-family: Inter;
      border-width: 0;
      margin-bottom:15px;
      color:#fff;
    }

    button.MuiButton-root:hover {
      background: #c02e1d;
    }

    a {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: left;
      color: #FE442F;
    }

    a:hover{
      color: #c02e1d;
    }
  }
}

@media(max-width: 768px) {
  .css-1otl85b-MuiGrid-root {
    & {
      padding:0 !important;
    }

    .Home-left {
      & {
        margin-bottom:20px;
      }
      li span {
        font-size: 16px;
      }
    }

    .Home-right {
      & {
        padding: 0;
      }
    }
  }
}