$dark-blue: #0C324D;
$grey: #C6C6C6;
$light-grey: #ececec;
$light-blue: #DBEFFD;
$light-blue-hover: #d1ebff;
$orange: #E55D4D;
.CheckDocument {

  button.MuiButton-root {
    background: #FE442F;
    box-shadow: 0px 8px 10px 0px #FE442F24;
    font-family: Inter;
    border-width: 0;
    margin-bottom:15px;
    color:#fff;
  }

  button.MuiButton-root:hover {
    background: #c02e1d;
  }

  h1 {
    & {
      font-family: Inter;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: $dark-blue;
      margin-bottom: 40px;
    }

    span {
      margin-right: 10px;
      color: $grey;
    }

  }

  .blockWrapper {
    & {
      padding:10px;
    }
    .block {
      & {
        background: $light-blue;
        padding:20px 10px;
        height:180px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .blockInsideWrapper {
        vertical-align: middle;
      }

      p {
        font-family: Inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: $dark-blue;
        margin-bottom:20px;
      }


      .MuiOutlinedInput-root {
        background: #F5F5F5;
        margin-bottom:15px;
      }

      label.MuiButton-outlined {
        border: 1px solid $orange;
        color: $orange;
        background: #fff;
        margin-bottom:15px;
      }
      button.MuiButton-outlined:hover {
        background: $light-grey;
      }


    }
  }

  img {
    max-width: 100%;
  }

  .description{
    text-align: left;
    padding-left:40px;
  }
}