$dark-blue: #0C324D;
$grey: #C6C6C6;
$light-blue: #DBEFFD;
$light-blue-hover: #d1ebff;
$orange: #E55D4D;
.GenerateDocument {
  h1 {
    & {
      font-family: Inter;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: $dark-blue;
      margin-bottom: 20px;
    }

    span {
      margin-right: 10px;
      color: $grey;
    }

  }

  .documentTypeWrapper {
    & {
      padding: 5px;
      height:150px;
      box-sizing: border-box;
    }

    .documentType {
      & {
        background: $light-blue;
        padding: 15px 15px;
        text-align: left;
        height:140px;
        box-sizing: border-box;
      }

      &:hover {
        background: $light-blue-hover;
        cursor: pointer;
      }

      p {
        color: $dark-blue;
        font-family: Inter;
        font-size: 15px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 15px;
      }
    }
  }
  .MuiFormControl-root {
    margin-bottom: 10px;
    width:100%;
  }

  button.MuiButton-root {
    background: #FE442F;
    box-shadow: 0px 8px 10px 0px #FE442F24;
    font-family: Inter;
    border-width: 0;
    margin-bottom:15px;
  }

  button.MuiButton-root:hover {
    background: #c02e1d;
  }

  input, .MuiOutlinedInput-root {
    background: #F5F5F5;
  }
  .autocomplete {

  }

  .Step3 {
    .SignatureWrapper {
      & {
        box-shadow: 0px 11px 18px 0px #F3F9FD;
        border: 1px solid #DBEFFD;
        background: #EBF6FE;
        padding: 50px 10px;
      }
      * {
        margin-bottom: 15px;
      }
      h3 {
        font-family: Inter;
        font-size: 34px;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;
        color: $dark-blue;
      }

      h4 {
        font-family: Inter;
        font-size: 50px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: center;
        color: $orange;
      }
      button:first-of-type {
        margin-right:15px;
      }

      p {
        margin-top:30px;
        padding: 0 50px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color:$dark-blue;
      }
    }
  }
  .sendEmail {
    & {
      margin-bottom: 3px !important;;
    }

    * {
      margin-bottom: 3px !important;;
    }
  }
  .emailMessages {
    & {
      margin-bottom: 3px !important;
    }

    * {
      margin-bottom: 3px !important;;
    }
  }
}

.MuiAutocomplete-popper {
  transition:0s !important;
}

@media(max-width: 768px) {
  .GenerateDocument{

    .Step3 {
      .SignatureWrapper {
        h3 {
          font-size: 20px;
          margin-bottom: 0;
        }
        h4 {
          font-size: 22px;
        }
        button:first-of-type {
          margin-right: 0;
        }
        .sendEmail {
          p {
            font-size: 15px;
            margin-bottom: 15px !important;
          }
        }

        .MuiInputBase-root {
          margin-bottom: 15px !important;
        }
        p {
          font-size: 15px;
          margin-bottom: 15px !important;
        }
      }
    }
  }
}