$orange: #E55D4D;
$light-grey: #F2F2F2;


.privateWrapper {
  & {
    margin-top: 40px;
  }

  .menuWrapper {
    .menu {
      & {
        background: $light-grey;
        padding: 20px 0 20px 20px;
        text-align: left;
      }

      a {
        & {
          font-family: 'Inter';
          font-size: 1rem;
          font-weight: 600;
          line-height: 0;
          letter-spacing: 0em;
          margin-top:37px;
          margin-bottom:13px;
          display: block;
          text-align: left;
        }

        &.active {
          border-bottom: 2px solid $orange;
          color: $orange;
        }

        span {
          background: $light-grey;
          padding-right: 10px;
        }
      }

      a:first-child {
        margin-top: 10px;
      }

      a:hover {
        color: $orange;
      }

    }
  }

  .privateContentWrapper {
    padding-left: 70px;
    margin-top:20px;
  }
}
button.MenuMobileButton {
  display: none;
}
@media(max-width: 768px) {
  button.MenuMobileButton {
    background: #FE442F;
    box-shadow: 0px 8px 10px 0px #FE442F24;
    font-family: Inter;
    border-width: 0;
    margin-bottom:15px;
    color:#fff;
    display: block;
  }

  button.MenuMobileButton:hover {
    background: #c02e1d;
  }
  .privateWrapper{
    & {
      margin-top: 0px;
    }
    .menuWrapper {
      & {
        display: none !important;
      }
      &.showed {
        display: block !important;
      }
    }

  }
  .privateContentWrapper {
    padding-left: 0 !important;
    padding-top: 15px;
  }
  .MuiGrid-root{
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;;
  ;
  }
}