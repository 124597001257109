$dark-blue: #0C324D;
$dark-grey: #878787;
$grey: #C6C6C6;
$light-grey: #ececec;
$light-blue: #DBEFFD;
$light-blue-hover: #d1ebff;
$orange: #E55D4D;

.DocumentList {
  h1 {
    & {
      font-family: Inter;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: $dark-blue;
      margin-bottom: 20px;
    }

    span {
      margin-right: 10px;
      color: $grey;
    }
  }

  .MuiFormControl-root {
    margin-bottom: 10px;
  }

  button.MuiButton-root {
    background: #FE442F;
    box-shadow: 0px 8px 10px 0px #FE442F24;
    font-family: Inter;
    border-width: 0;
    margin-bottom: 15px;
  }

  button.MuiButton-root:hover {
    background: #c02e1d;
  }

  input, .MuiOutlinedInput-root {
    background: #F5F5F5;
  }

  table {
    & {

    }

    th {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: $dark-blue;
    }
    td {
      & {
        font-family: Inter;
        font-size: 11px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: $dark-blue;
      }

      span {
        display: block;
      }

      span:last-of-type {
        color: $dark-grey;
      }
      span.link {
        color:#FE442F;
        cursor: pointer;
        transition: 0.3s;
      }
      span.link:hover {
        color:#c02e1d;
      }
    }
  }
  .MuiPagination-ul{
    justify-content: center;
    margin-top:10px;
  }
}