$orange: #c02e1d;

$light-orange: #E55D4D;

.MuiDialogContent-root a {
  color: $orange  ;
}

.MuiDialogContent-root a:hover {
  text-decoration: underline;
}

.MuiDialogActions-root button {
  background: $orange;
}

.MuiDialogActions-root button:hover {
  background: $light-orange;
}

.AppLayout {
  & {
    padding: 25px 30px;
  }

  .AppHeader {
    & {

    }

    .MuiTypography-body2 {
      color: gray;;
    }

    .AppHeaderLogoWrapper img {
      max-width: 100%;
    }

    .AppHeaderTitle {
      & {
        color: $orange;
      }

      h5 {
        font-weight: 600;
      }
    }

    .AppHeaderButtonWrapper {
      & {
        text-align: right;

      }



      .AppHeaderButton {
        & {
          display: inline-block;
          text-align: center;
          cursor: pointer;
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0;
        }
        img {
          width: 37px;
          height: 37px;
        }
        p {
          font-family: Inter;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          color: $light-orange;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .AppLayout {

    .AppHeader {

      .AppHeaderLogoWrapper {
        margin-bottom: 20px;
      }

      .AppHeaderTitle {
        & {
          margin-bottom: 20px;
        }
      }

      .AppHeaderButtonWrapper {
        & {
          text-align: center;
        }
      }
    }
  }
}