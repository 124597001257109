p {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 24.5px;
  text-align: center;
  color: #0C324D;
}


.offerBoxWrapper {
  & {
    padding: 3px;
  }

  .offerBox {
    & {
      border: 1px solid #DBEFFD;
      background: #EBF6FE;
      padding:  12px 3px;
    }

    .offerName {
      font-family: Inter;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      text-align: center;
      color: #FE442F;

    }

    li {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.4px;
      text-align: left;
      color: #0C324D;
      list-style-image: url("img/list-r.png");
      list-style-position: outside;
    }

    .offerSubName {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.5px;
      text-align: center;
      color: #567184;
    }

    .offerPrice {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #0C324D;
      margin-top:7px;
      margin-bottom:20px;
    }
  }

}