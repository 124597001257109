$dark-blue: #0C324D;
$grey: #C6C6C6;
$light-blue: #DBEFFD;
$light-blue-hover: #d1ebff;
$orange: #E55D4D;

.SubscriptionData{
  h3 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $dark-blue;
  }
  p {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: $dark-blue;

  }

  button.MuiButton-root {
    background: #FE442F;
    box-shadow: 0px 8px 10px 0px #FE442F24;
    font-family: Inter;
    border-width: 0;
    margin-bottom:15px;
    color:#fff;
  }

  button.MuiButton-root:hover {
    background: #c02e1d;
  }
}